.app-auth {
	padding: 2rem 0;
}

.app-form {
	padding:16px;
	padding:1rem;
}

.app-form-input {
  position:relative;
	input, input:focus, input:active {
		-webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
    background-color:transparent;
	}
  label {
    text-transform:uppercase;
  }
  input:disabled, input:disabled:focus, input:disabled:active {
    background-color:#e9ecef;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
input {
  color:#000;
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #ccc !important;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #ccc !important;
    opacity:  1 !important;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #ccc !important;
    opacity:  1 !important;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    #ccc !important;
  }
}

.app-form-group {
  margin-bottom:1.25rem;
  margin-bottom:20px;
}

.app-form-action {
  padding:24px 0;
  padding:1.5rem 0;
  > .btn {
    margin:0 auto 1.5rem;
    display:block;
  }
}

.app-form-errors {
  color:#a50000;
  min-height:32px;
  min-height:2rem;
}

.app-form-meta {
  margin-bottom:16px;
  margin-bottom:1rem;
  text-align:center;
  > p {
    font-size: 14px;
    color: rgb(108, 108, 108);
    text-align: center;
    margin:0;
  }
}

.btn {
  > svg {
    vertical-align: sub;
  }
}

.btn-clip-copy {
  position: absolute;
  bottom: 0.9rem;
  right: 0.5rem;
  border: none;
  background: none;
  height: calc(2.25rem + 2px);
  line-height: 2.5rem;
  cursor: pointer;
}

table {
	table-layout: fixed;
	td, th {
		word-break: break-word;
		max-width:25% !important;
		line-height: 1.5rem;
		i {
			vertical-align:middle;
		}
		span {
			min-height:2rem;
			display:inline-block;
		}
	}
}

table.task-days {
	th, tr {
		border:none;
	}
}

.react-player-wrapper {
	position: relative;
	padding-top: 56.25%;
	width: 100%;
}
.react-player {
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
}