body {
  margin:0;
  padding:0;
}
textarea:focus,
button:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select:focus,
.uneditable-input:focus {
  // border: none !important;
  outline: none !important;
  box-shadow:none;
  outline: thin dotted \9;
  /* IE6-9 */

  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}
input:focus {
  outline: none !important;
  box-shadow:none !important;
}
select {
  cursor:pointer;
}
ul {
	padding-left:0;
  margin-bottom:0;
}
label {
  margin-bottom:0;
}
iframe {
  max-width:100%;
}
a, a:hover, a:focus {
  text-decoration:none !important;
}