@mixin borderRadius($value) {
	border-radius:$value !important;
	-moz-border-radius:$value !important;
	-webkit-border-radius:$value !important;
}

@mixin borderTopLeftRadius($value) {
	border-top-left-radius:$value !important;
	-moz-top-left-border-radius:$value !important;
	-webkit-top-left-border-radius:$value !important;
}

@mixin borderTopRightRadius($value) {
	border-top-right-radius:$value !important;
	-moz-top-right-border-radius:$value !important;
	-webkit-top-right-border-radius:$value !important;
}

@mixin borderBottomRightRadius($value) {
	border-bottom-right-radius:$value !important;
	-moz-bottom-right-border-radius:$value !important;
	-webkit-bottom-right-border-radius:$value !important;
}

@mixin borderBottomLeftRadius($value) {
	border-bottom-left-radius:$value !important;
	-moz-bottom-left-border-radius:$value !important;
	-webkit-bottom-left-border-radius:$value !important;
}
@mixin bgCover() {
 	-webkit-background-size: cover; /* For WebKit*/
	-moz-background-size: cover;    /* Mozilla*/
	-o-background-size: cover;      /* Opera*/
	background-size: cover;
	background-position:center center;
	-ms-behavior: url(/src/polyfills/backgroundsize.min.htc);
	object-fit:cover;
}
@mixin borderBox() {
	-webkig-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}