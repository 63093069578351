/*----------------------------------------
  Modern Dashboard
------------------------------------------*/

//Variables
@import "variables.scss";

.chartist-tooltip {
   z-index: 10000 !important;
}
.total-transaction-tooltip,
.current-balance-tooltip,
.user-statistics-tooltip,
.conversion-ratio-tooltip {
   background: rgba(220,0,0,.8);
   color: #fff;
   &:before {
      border-top-color: rgba(220,0,0,.8);
   }
}

// For Icons

.card {
   .card-content {
      .card-title {
         i.material-icons {
            cursor: pointer;
         }
      }
   }
}

// Current balance
.current-balance-container {
   position: relative;
   height: 170px;
}

.current-balance-container > * {
   position: absolute;
   width: 100%;
}

#current-balance-donut-chart {
   height: 170px;
   filter: drop-shadow(0px 10px 4px rgba(133, 3, 168, 0.2));
   .ct-series-a {
      .ct-slice-donut {
         stroke: rgba(220,0,0,1);
      }
   }
   .ct-series-b {
      .ct-slice-donut {
         stroke: #f6f6f6;

      }
   }


}

// Total Transaction
.total-transaction-container {
   position: relative;
   height: 240px;
   > * {
      position: absolute;
      width: 100%;
   }
}
#total-transaction-line-chart {
   height: 240px;
   .ct-series-a {
      &.ct-series .ct-line {
         stroke: url(#lineLinearStats) !important;
         stroke-width: 3px;
      }
      .ct-point {
         fill: transparent;
         stroke: transparent;
         &:hover {
            fill: url(#lineLinearStats) !important;
            stroke-width: 4;
            stroke: #fff;
         }
      }
      .ct-point-circle {
         fill: #fff;
         stroke-width: 4;
         stroke: rgba(220,0,0,1);
      }
   }
   .ct-grid {
      stroke: rgba(0, 0, 0, 0.05);
      stroke-width: 1px;
      stroke-dasharray: 0px;
   }
}
.total-transaction-shadow {
   filter: drop-shadow(0px 30px 4px rgba(133, 3, 168, 0.25));
}

// User Statistics
.user-statistics-card {
   .collection {
      .collection-item {
         min-height: auto;
      }
   }
}
.user-statistics-container {
   position: relative;
   height: 150px;
}
.user-statistics-container > * {
   position: absolute;
   width: 100%;
}

#user-statistics-bar-chart {
   height: 150px;
   filter: drop-shadow(4px 10px 4px rgba(133, 3, 168, 0.2));
   .ct-series-a {
      .ct-bar {
         stroke: url(#barGradient1);
      }
      .ct-slice-pie {
         fill: #ff4bac;
      }
   }
   .ct-series-b {
      .ct-bar {
         stroke: url(#barGradient2);
      }
      .ct-slice-pie {
         fill: #8133ff;
      }
   }
}

// Recent Buyers
.recent-buyers-card {
   .collection {
      border: none;
      .collection-item {
         min-height: auto;
         border-bottom: none;
         padding-left: 55px;
         &.avatar:not(.circle-clipper) > .circle {
            left: 0;
         }
         &.avatar .secondary-content {
            right: 0px;
            color: $primary-color;
         }
      }
   }
}
// Conversion Ratio
#conversion-ration-bar-chart {
   filter: drop-shadow(0px 20px 10px rgba(129, 51, 255, 0.4));
   .ct-series-a {
      .ct-bar {
         stroke: url(#barGradient2);
      }
   }
   .ct-series-b {
      .ct-bar {
         stroke: url(#barGradient1);
      }
   }
   .ct-series-c {
      .ct-bar {
         stroke: #e4e4e4;
      }
   }
}

// Subscriber List
.subscriber-list-card {
   table {
      th {
         text-transform: uppercase;
         font-weight: 800;
         font-size: 0.9rem;
      }
      td:first-child,
      th:first-child {
         padding: 15px 5px 15px 25px;
      }
      td:last-child,
      th:last-child {
         padding: 15px 25px 15px 5px;
      }
   }
}





// Animation User Stat Chart
#user-statistics-bar-chart {
   .ct-series-a {
      .ct-slice-pie {
         opacity: 0;
         animation: barChart 0.2s forwards ease-in;
         animation-delay: 0.9s;
      }
   }

   .ct-series-b {
      .ct-slice-pie {
         opacity: 0;
         animation: barChart 0.2s forwards ease-in;
         animation-delay: 0.9s;
      }
   }
}

@keyframes barChart {
   to {
      opacity: 1;
   }
}
