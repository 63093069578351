.select-block {
	position:relative;
	select {
	  padding:4px 25px 8px 10px;
	  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @include borderRadius(2px);
    font-size:16px;
    color:#6d6e71;
	}
}
.select-block {
  position:relative;
  select {
    padding-right:25px;
  }
  .icon, svg {
    color:inherit;
    position: absolute;
    pointer-events: none;
    top: 10px;
    // bottom: 1px;
    // padding-top: 0.7em;
    line-height: 1;
    right: 0;
    width: 2em;
    text-align: center;
    transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    -moz-transform: scale(0.7, 0.7);
    -ms-transform: scale(0.7, 0.7);
    -o-transform: scale(0.7, 0.7);
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand')";
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand');
  }
}