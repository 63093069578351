.app-challenges-list-item {
	padding:8px 0;
	padding:0.5rem 0;
	&.head {
		border-bottom: 1px solid #ccc;
	}
	&:nth-child(odd) {

	}
	&:nth-child(even) {
		background: #f1f1f1;
	}
	.btn-link {
		line-height:14px;
		line-height:0.875rem;
	}
}