.app-bg {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: -1;
	background-color: #bdbdbd;
}

.app-bg:after {
	opacity: .8;
	background-color: #fff;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}