.program-head-section {
	> .head, .sub-head {
    font-weight: bold;
    margin-bottom: 4px;
    margin-bottom: 0.25rem;
	}

	> .head {
		font-size: 20px;
		font-size: 1.25rem;
	}

	> .sub-head {
		font-size: 16px;
		font-size: 1rem;
	}
	p {
		margin-bottom:8px;
		margin-bottom:0.5rem;
		> span.highlight {
			color:#0195ff;
		}
	}
}