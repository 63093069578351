// MARGINS
  .m0 { margin:0; } .m5 { margin: 5px;} .m10 { margin: 10px;} .m15 { margin: 15px;}

  .str5 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .str10 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .str15 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .f-pb40 {
    padding-bottom:40px !important;
  }

  .mb0 { margin-bottom: 0 !important;} .mb5 { margin-bottom: 5px;} .mb10 {  margin-bottom: 10px;} .mb15 {  margin-bottom: 15px;} .mb20 {  margin-bottom: 20px;} .mb30 {  margin-bottom: 30px;} .mb40 {  margin-bottom: 34px;} .mb50 {  margin-bottom: 50px;} .mb60 {  margin-bottom: 60px;} .mb70 {  margin-bottom: 70px;} .mb80 {  margin-bottom: 80px;} .mb90 {  margin-bottom: 90px;} .mb100 { margin-bottom: 100px;}
  .mb-10 { margin-bottom: -10px;} .mb-20 { margin-bottom: -20px;} .mb-30 { margin-bottom: -30px;} .mb-40 { margin-bottom: -34px;} .mb-50 { margin-bottom: -50px;} .mb-60 { margin-bottom: -60px;} .mb-70 { margin-bottom: -70px;} .mb-80 { margin-bottom: -80px;} .mb-90 { margin-bottom: -90px;} .mb-100 { margin-bottom: -100px;}

  .mt0 { margin-top: 0;} .mt2 {margin-top:2px;} .mt5 { margin-top:5px;} .mt7 { margin-top:7px;} .mt10 { margin-top: 10px;} .mt13 { margin-top: 13px;} .mt15 { margin-top: 15px;} .mt20 { margin-top: 20px;} .mt25 { margin-top: 25px;} .mt30 { margin-top: 30px;} .mt40 { margin-top: 40px;} .mt46 { margin-top: 46px;} .mt50 { margin-top: 50px;} .mt60 { margin-top: 60px;} .mt70 { margin-top: 70px;} .mt80 { margin-top: 80px;} .mt90 { margin-top: 90px;} .mt100 { margin-top: 100px;} .mt110 { margin-top: 110px;} .mt120 { margin-top: 120px;}

  .mt-5 { margin-top:-5px;} .mt-10 { margin-top: -10px;} .mt-20 { margin-top: -20px;} .mt-25 { margin-top: -25px;} .mt-30 { margin-top: -30px;} .mt-35 { margin-top: -35px;} .mt-40 { margin-top: -34px;} .mt-50 { margin-top: -50px;} .mt-60 { margin-top: -60px;} .mt-70 { margin-top: -70px;} .mt-80 { margin-top: -80px;} .mt-90 { margin-top: -90px;} .mt-100 { margin-top: -100px;}

  .ml0 { margin-left: 0;} .ml3 { margin-left: 3px;} .ml5 { margin-left: 5px;} .ml7 { margin-left: 7px;} .ml10 { margin-left: 10px;} .ml15 { margin-left: 15px;} .ml20 { margin-left: 20px;} .ml30 { margin-left: 30px;} .ml40 { margin-left: 40px;} .ml45 { margin-left: 45px;} .ml50 { margin-left: 50px;} .ml55 { margin-left: 55px;} .ml60 { margin-left: 60px;} .ml70 { margin-left: 70px;} .ml80 { margin-left: 80px;} .ml90 { margin-left: 90px;} .ml100 { margin-left: 100px;}
  .ml-10 { margin-left: -10px;} .ml-15 { margin-left: -15px;} .ml-7 { margin-left: -7px;} .ml-20 { margin-left: -20px;} .ml-30 { margin-left: -30px;} .ml-40 { margin-left: -34px;} .ml-50 { margin-left: -50px;} .ml-60 { margin-left: -60px;} .ml-70 { margin-left: -70px;} .ml-80 { margin-left: -80px;} .ml-90 { margin-left: -90px;} .ml-100 { margin-left: -100px;}

  .mr0 { margin-right: 0;} .mr5 { margin-right:5px;} .mr7 { margin-right:7px;} .mr10 { margin-right: 10px;} .mr13 { margin-right: 13px;} .mr15 { margin-right: 15px;} .mr20 { margin-right: 20px;} .mr30 { margin-right: 30px;} .mr40 { margin-right: 34px;} .mr50 { margin-right: 50px;} .mr60 { margin-right: 60px;} .mr70 { margin-right: 70px;} .mr80 { margin-right: 80px;} .mr90 { margin-right: 90px;} .mr100 { margin-right: 100px;}
  .mr-7 { margin-right: -7px;} .mr-10 { margin-right: -10px;} .mr-20 { margin-right: -20px;} .mr-30 { margin-right: -30px;} .mr-40 { margin-right: -34px;} .mr-50 { margin-right: -50px;} .mr-60 { margin-right: -60px;} .mr-70 { margin-right: -70px;} .mr-80 { margin-right: -80px;} .mr-90 { margin-right: -90px;} .mr-100 { margin-right: -100px;}

// PADDINGS
  .pt0 { padding-top: 0 !important;} .pt5 { padding-top: 5px;} .pt7 { padding-top: 7px;} .pt10 { padding-top: 10px;} .pt13 { padding-top: 13px;} .pt15 { padding-top: 15px;} .pt20 { padding-top: 20px;} .pt25 { padding-top: 25px;} .pt30 { padding-top: 30px;} .pt40 { padding-top: 34px;} .pt50 { padding-top: 50px;} .pt60 { padding-top: 60px;} .pt70 { padding-top: 70px;} .pt80 { padding-top: 80px;} .pt90 { padding-top: 90px;} .pt100 { padding-top: 100px;}
  .pt-10 { padding-top: -10px;} .pt-20 { padding-top: -20px;} .pt-30 { padding-top: -30px;} .pt-40 { padding-top: -34px;} .pt-50 { padding-top: -50px;} .pt-60 { padding-top: -60px;} .pt-70 { padding-top: -70px;} .pt-80 { padding-top: -80px;} .pt-90 { padding-top: -90px;} .pt-100 { padding-top: -100px;}


  .pb0 { padding-bottom: 0 !important;} .pb5 { padding-bottom: 5px;} .pb10 { padding-bottom: 10px;} .pb13 { padding-bottom: 13px;} .pb15 { padding-bottom: 15px;} .pb20 { padding-bottom: 20px;} .pb30 { padding-bottom: 30px;} .pb40 { padding-bottom: 34px;} .pb50 { padding-bottom: 50px;} .pb60 { padding-bottom: 60px;} .pb70 { padding-bottom: 70px;} .pb80 { padding-bottom: 80px;} .pb90 { padding-bottom: 90px;} .pb100 { padding-bottom: 100px;}
  .pb-10 { padding-bottom: -10px;} .pb-20 { padding-bottom: -20px;} .pb-30 { padding-bottom: -30px;} .pb-40 { padding-bottom: -34px;} .pb-50 { padding-bottom: -50px;} .pb-60 { padding-bottom: -60px;} .pb-70 { padding-bottom: -70px;} .pb-80 { padding-bottom: -80px;} .pb-90 { padding-bottom: -90px;} .pb-100 { padding-bottom: -100px;}

  .pl0 { padding-left: 0 !important;} .pl3 {padding-left:3px;} .pl5 { padding-left: 5px;} .pl10 { padding-left: 10px;} .pl13 { padding-left: 13px;} .pl15 { padding-left: 15px;} .pl20 { padding-left: 20px;} .pl25 { padding-left: 25px;} .pl30 { padding-left: 30px;} .pl40 { padding-left: 34px;} .pl50 { padding-left: 50px;} .pl60 { padding-left: 60px;} .pl70 { padding-left: 70px;} .pl80 { padding-left: 80px;} .pl90 { padding-left: 90px;} .pl100 { padding-left: 100px;}
  .pl-10 { padding-left: -10px;} .pl-20 { padding-left: -20px;} .pl-30 { padding-left: -30px;} .pl-40 { padding-left: -34px;} .pl-50 { padding-left: -50px;} .pl-60 { padding-left: -60px;} .pl-70 { padding-left: -70px;} .pl-80 { padding-left: -80px;} .pl-90 { padding-left: -90px;} .pl-100 { padding-left: -100px;}

  .pr0 { padding-right: 0 !important;} .pr3 {padding-right:3px;} .pr5 {padding-right:5px;} .pr10 { padding-right: 10px;} .pr13 { padding-right: 13px;} .pr15 { padding-right: 15px;} .pr20 { padding-right: 20px;} .pr30 { padding-right: 30px;} .pr40 { padding-right: 34px;} .pr50 { padding-right: 50px;} .pr60 { padding-right: 60px;} .pr70 { padding-right: 70px;} .pr80 { padding-right: 80px;} .pr90 { padding-right: 90px;} .pr100 { padding-right: 100px;}
  .pr-10 { padding-right: -10px;} .pr-20 { padding-right: -20px;} .pr-30 { padding-right: -30px;} .pr-40 { padding-right: -34px;} .pr-50 { padding-right: -50px;} .pr-60 { padding-right: -60px;} .pr-70 { padding-right: -70px;} .pr-80 { padding-right: -80px;} .pr-90 { padding-right: -90px;} .pr-100 { padding-right: -100px;}

  .p0 {padding:0 !important;} .p5 { padding: 5px;} .p10 { padding: 10px;} .p15 { padding: 15px;} .p20 { padding: 20px;}

  .d-pad-right {
    @media (min-width: 576px) {
      padding-right:1rem;
    }
  }

  .mtb10 { margin-top:10px; margin-bottom:10px;} .mtb20 { margin-top:20px; margin-bottom:20px;} .mtb30 { margin-top:30px; margin-bottom:30px;} .mtb40 { margin-top:40px; margin-bottom:40px;} .mtb50 { margin-top:50px; margin-bottom:50px;} .mtb60 { margin-top:60px; margin-bottom:60px;} .mtb70 { margin-top:70px; margin-bottom:70px;} .mtb80 { margin-top:80px; margin-bottom:80px;} .mtb90 { margin-top:90px; margin-bottom:90px;} .mtb100 { margin-top:100px; margin-bottom:100px;}

  .mlr10 { margin-left:10px; margin-right:10px;} .mlr20 { margin-left:20px; margin-right:20px;} .mlr30 { margin-left:30px; margin-right:30px;} .mlr40 { margin-left:40px; margin-right:40px;} .mlr50 { margin-left:50px; margin-right:50px;} .mlr60 { margin-left:60px; margin-right:60px;} .mlr70 { margin-left:70px; margin-right:70px;} .mlr80 { margin-left:80px; margin-right:80px;} .mlr90 { margin-left:90px; margin-right:90px;} .mlr100 { margin-left:100px; margin-right:100px;}

  .mp0 {
    margin:0;
    padding:0;
  }
  .nopad {
    padding:0 !important;
  }
  .nopad-lr {
    padding-left:0 !important; padding-right:0 !important;
  }

// CLEARANCE
  .clear:after {
    content:'' !important;
    height:0 !important;
    width:100% !important;
    display:block !important;
    clear:both !important;
  }

  .clear-float {
    float:none !important;
  }

  .h100 .bg-block { padding:0; height:100px;} .h200 .bg-block { padding:0; height:200px;} .h300 .bg-block { padding:0; height:300px;} .h400 .bg-block { padding:0; height:400px;} .h500 .bg-block { padding:0; height:500px;} .h600 .bg-block { padding:0; height:600px;} .h700 .bg-block { padding:0; height:700px;} .h800 .bg-block { padding:0; height:800px;} .h900 .bg-block { padding:0; height:900px;}

// POSITIONING
  .t8 {
    top:8px;
  }

// WIDTH & HEIGHT
  .w100 {
    width:100% !important;
  }
  .h100 {
    height:100%;
  }

// FLOAT
  .pull-left {float:left;} .pull-right {float:right;}

// TEXT
  .text-center { text-align:center;} .text-left {text-align:left;} .text-right {text-align:right;}
  .line-tru {  text-decoration: line-through; }
  .text-white {color:#fff;}
  .uppercase {text-transform:uppercase;}


// ELEMENT ALIGN
  .box-center { margin-left:auto; margin-right:auto;}

// DROP SHADOW
  // .shadowed {
  //   -webkit-filter: drop-shadow(0 0 3px rgba(255,255,255,0.5));
  //   filter: drop-shadow(0 0 2px rgba(255,255,255,0.5));
  //   filter: url(shadow.svg#drop-shadow);
  //   -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  //   filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  // }

// NO SELECT
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

// FORMS
  .clean-textarea, .card-textarea {
    height:30px;
    padding: 7px;
    outline: none !important;
    border:none;
    outline: none;
    overflow:hidden;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -moz-appearance:none;
    -webkit-appearance:none;
    resize:none;
  }
  .clean-input, .card-input {
    padding: 7px;
    border:none;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -moz-appearance:none;
    -webkit-appearance:none;
    resize:none;
  }
  .clean-input, .clean-textarea {
    background: none;
    border:none !important;
    padding:0;
  }

  .cleanSelect {
    position:relative;
  }
  .cleanSelect:before {
    content: '\25bc';
    position: absolute;
    pointer-events: none;
    color: #82888a;
    top: 0;
    bottom: 1px;
    padding-top: 0.7em;
    line-height: 1;
    right: 0;
    width: 2em;
    text-align: center;
    transform: scale(0.84, 0.42);
    -webkit-transform: scale(0.84, 0.42);
    -moz-transform: scale(0.84, 0.42);
    -ms-transform: scale(0.84, 0.42);
    -o-transform: scale(0.84, 0.42);
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand')";
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand');
  }
  .label-block {
    display:block;
  }

// POINTER CURSOR
  .point {
    cursor: pointer;
  }

// VALIDATION
  .errored {
    color:red;
    list-style:none;
    padding:0;
    margin-bottom:0;
  }


  @media screen and (max-width: 1024px) {
    img.bg {
      left: 50%;
      margin-left: -512px;
    }
  }
  .bg-cover-wrap img {
    position: fixed; 
    top: 0; 
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }
  img.bg {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    z-index:-1;
  }

// POSITIONING
  .pb-icon-top-right, .pb-icon-top-right-near, .abs-bottom-right {
    position:absolute;
    z-index:0;
  }
  .pb-icon-top-right {
    top:15px;
    right:15px;
  }
  .pb-icon-top-right-near {
    top:5px;
    right:5px;
  }
  .abs-bottom-right {
    bottom:5px;
    right:5px;
  }

// BORDERS
  .bb-white-bold {
    border-bottom: 2px solid white;
  }
  .bb-white-thin {
    border-bottom: 1px solid white;
  }
  .bt-white-thin {
    border-top: 1px solid white;
  }
  .bt-white-bold {
    border-top: 2px solid white;
  }
  .br-grey-thin {
    border-right:1px solid #ccc;
  }
  .b-thin {
    border-style: solid;
    border-width: 1px;
  }
  .bt-grey-thin {
    border-top: 1px solid #ccc;
  }
  .bb-grey-thin {
    border-bottom: 1px solid #ccc;
  }
  .bb-grey-dashed {
    border-bottom: 1px dashed #ccc;
  }

// DELIMITERS
  .d-vertical-abs-center {
    position: absolute;
    height:100%;
    width:0;
    left:50%;
  }

// ELLIPSIS
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

// FLEX WIDTH
  .w-left-60 {
    float: left;
    width: calc(100% - 60px);
  }
  .w-right-60 {
    float: right;
    width: calc(100% - 60px);
  }

// LINE BREAKS
  .pre {
    white-space: pre-wrap;
  }

// BREAK 
  .break-word {
  overflow-wrap: break-word;
}