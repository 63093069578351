.btn {
	@include borderRadius(0);
	font-weight:300;
}
.btn:active, .btn:focus {
	box-shadow:none;
}

.btn-link {
	border:none;
	background:none;
	box-shadow:none;
}

.btn-plain {
	padding:0;
	border:none !important;
	background:none !important;
	box-shadow:none !important;
	color:#000;

	// &.left-icon {
	// 	padding-left: -0.5rem;
	// }
	// &.right-icon {
	// 	padding-right: -0.5rem;
	// }
}

// VARIATIONS
.btn-brand {
	&, &:hover, &:active, &:focus {
		background-color:#0195ff;
		color:#fff !important;
	}
}
.btn-facebook {
	&, &:hover, &:active, &:focus {
		background-color:#4267b2;
		color:#fff !important;
	}
}