*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }

a {
  color: #4e4a46;
  text-decoration: none;

  &:hover,
  &:focus {
    // color: #c94e50;
  }
}

.description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
}

.demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;

  a {
    display: inline-block;
    margin: 0.75em;
    padding: 1.35em 1.1em;
    width: 15em;
    background: #fffce1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    border-top-left-radius: 20px 50px;
    border-top-right-radius: 20px 50px;
    border-bottom-right-radius: 20px 50px;
    border-bottom-left-radius: 20px 50px;
    cursor: pointer;

    &.current-demo {
      background: #c94e50;
      color: #fffce1;
    }
  }
}

.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;

  &.left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.active {
    background: #c94e50;
    color: #fffce1;
  }
}

//
// Burger menu custom styles
//
.bm-burger-button {
  position: fixed;
  width: 32px;
  width: 2rem;
  height: 28px;
  height: 1.75rem;
  left: 16px;
  left: 1rem;
  top: 16px;
  top: 1rem;
}

.right .bm-burger-button {
  left: initial;
  right: 16px;
  right: 1rem;
}

.bm-burger-bars {
  background: #000;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-menu {
  background: #fff;

  -webkit-box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.15);

  a {
    color: #000;

    &:hover,
    &:focus {
      color: #000;
    }
  }
}

.bm-item-list {
  > a {
    padding: 16px 32px;
    padding: 1rem 2rem;
    font-weight:400;

    span {
      margin-left: 10px;
      font-weight: 700;
    }
    > svg {
      vertical-align: text-top;
    }
    &.active {
      background: #0195ff;
      color:#fff;
    }
    &.padded {
      padding-left:48px;
      padding-left:3rem;
    }
    
  }
  > span.menu-divider {
    display: block;
    outline: none;
    margin: 1rem 0;
    height: 1px;
  }

} 

//
// Mixins
//
#slide,
#stack,
#reveal,
.menu-1 {
  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
}

#bubble,
#push,
.menu-2 {
  .bm-cross {
    background: #999;
  }

  .bm-menu {
    padding: 3.4em 1em 0;
  }

  a {
    padding: 1em;
  }

  i {
    font-size: 1.7em;
    vertical-align: middle;
    color: #282a35;
  }
}

#elastic,
.menu-3 {
  .bm-cross {
    background: #888;
  }

  .bm-menu {
    padding: 2em 1em;
    font-size: 1.15em;
  }

  i {
    opacity: 0.5;
  }

  span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75em;
  }
}

#scaleDown,
#scaleRotate,
#pushRotate,
#fallDown,
.menu-4 {
  .bm-cross {
    background: #888;
  }

  h2 {
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

    i {
      margin-left: 0.2em;
    }

    span {
      font-size: 1.6em;
      font-weight: 700;
    }
  }

  a {
    padding: 1em;
    text-transform: uppercase;
    transition: background 0.2s, box-shadow 0.2s;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

    span {
      letter-spacing: 1px;
      font-weight: 400;
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
      color: #b8b7ad;
    }
  }
}

.app-menu-close {
  display: block;
  outline: none;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0;
  height: 3.8rem;
  width: 3.8rem;
  cursor:pointer;
}