$bottom-margin: 50px;
$line-width: 2px;
$line-color: #e6e6e6;
$bg-color: #EFE6E2;


.hv-wrapper {
  display: flex;
  .hv-item{
    display: flex;
    flex-direction: column;
    margin: 0 auto auto;
    .hv-item-parent{
      margin-bottom: $bottom-margin;
      position: relative;
      display: flex;
      justify-content: center;      
      &:after{
        position: absolute;
        content: '';
        width: $line-width;
        height: $bottom-margin / 2;
        bottom: 0;
        left: 50%;
        background-color: $line-color;
        transform: translateY(100%);
        // z-index: -1;
      }
    }
    .hv-item-children{
      display: flex;
      justify-content: center;

      .hv-item-child{
        padding: 0 15px;
        position: relative;
        min-width:10rem;
        &:before, &:not(:only-child):after{
          content: '';
          position: absolute;
          background-color: $line-color;
          left: 0;
          // z-index: -1;
        }
        
        &:before{
          left: 50%;
          top: 0;
          transform: translateY(-100%);
          width: $line-width;
          height: $bottom-margin / 2;
          
        }
        
        &:after{
          top: -$bottom-margin / 2;
          transform: translateY(-100%);
          height: $line-width;
          width: 100%;
        }
        
        &:first-child:after{
          left: 50%;
          width: 50%;
        }

        &:last-child:after{
          width: calc(50% + 2px);
        }        
        
      }
    }
    .hv-item-expand {
    	padding: 0.5em 0;
	    text-align: center;
	    button.btn-floating {
	    	-webkit-box-shadow: 0 0px 10px -3px #ccc !important;
      	box-shadow: 0 0px 10px -3px #ccc !important;
      	position: absolute;
				top: calc(100% + 0.55rem);
				left: calc(50% - 15.2px);
		    background-color: #F6F7FB;
	    }
    }
  }
}

section{
	min-height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	// padding: 50px 0;
	position: relative;
	.github-badge{
	position: absolute;
	top: 0;
	left: 0;
	}
    h1{
        text-align: center;
        margin-bottom: 70px;
    }
    .hv-container {
      flex-grow: 1;
			overflow: auto;
      justify-content: center;
      padding: 50px 0;
      min-width:100%;
    }
}

// BASIC STYLE
.basic-style{
    background-color: #EFE6E2;
    &>h1{
        color: #DE5454;
    }
}

p.simple-card{
  margin: 0;
  background-color: #fff;
  color: #DE5454;
  padding: 30px;
  border-radius: 7px;
  min-width: 100px;
  text-align: center;
  box-shadow: 0 3px 6px rgba(#CC8367, 0.22);
}

.hv-item-parent{
    p{
        font-weight: bold;
        color: #DE5454;
    }
}


// MANAGEMENT HIERARCHY
.management-hierarchy {
  > h1 {
    color: #FFF;
  }
  .person {
    text-align: center;
    padding: 0 0.5rem;
    > img, > .avatar-placeholder {
      height: 110px;
      width: 110px;
      border: 5px solid #FFF;
      border-radius: 50%;
      overflow: hidden;
    	background-color: #fff;
    }
    > .avatar-placeholder {
    	display:block;
	    margin: 0 auto 6px;
    	display: flex;
	    flex-direction: row;
	    align-items: center;
	    justify-content: center;
	    font-size: 2rem;
	    font-weight: 300;
	    color: $secondary-color;
    }
    > p.name {
      // background-color: #F6F7FB;
      padding: 0.5rem 0.5rem;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: normal;
	    color: $secondary-color;
      margin: 0;
      position: relative;
      width: 10rem;
	    max-width: 100%;
	    height: 6rem;
	    justify-content: center;
	    align-items: center;
      display: flex;
	    flex-direction: column;
	    line-height:1rem;
	    // border: 1px solid #e8e8e8;
      b {
          color:rgba(#00BCFF, 0.5);
      }
      &:before{
        // content: '';
        position: absolute;
        width: 2px;
        height: 6px;
        background-color: $line-color;
        left: 50%;
        top: 0;
        transform: translateY(-100%);
        z-index: -1;
      }
      span.meta-data {
  	    font-size: 10px;
		    line-height: 0.875rem;
		    margin-top:0.5rem;
		    margin-bottom:0.25rem;
      }
    }
    > img, > p.name, .avatar-placeholder {
    	// -webkit-box-shadow: 0 0px 10px -3px #ccc;
    	// box-shadow: 0 0px 10px -3px #ccc;
    }
  }
}