.card.card-nopad {
	.card-content {
		padding:0;
	}
}

.app-card {
	width: 768px;
	max-width: 100%;
	margin: 0 auto;
	padding: 0;
	background-color: #fff;
  position: relative;
  color: #14064c;
  border:none;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

	&.narrow {
		width: 550px;
	}

	&.thru {
		background-color: #ffffff85;
	}
  a, a:hover {
  	color:#007bff;
  }

  a.btn, a.btn:hover {
  	color:#fff;
  }
}

.app-card-head {
	padding: 1rem;
	&.centered {
		text-align:center;
	}
	h1 {
		font-size:28px;
		font-size:1.75rem;
		text-transform:uppercase;
		font-weight:300;
		margin:24px 0 32px;
		margin:1rem 0 1rem;
	}
	&.app-card-head-2 {
		padding: 2rem;
	}
	&.app-card-head-3 {
		padding: 3rem;
	}
	&.app-card-head-2250 {
		padding: 2rem 2.5rem 0rem;
	}
	&.app-card-head-230 {
		padding: 2rem 3rem 0rem;
	}
	&.app-card-head-4 {
		padding: 4rem;
	}
}

.app-card-header-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
  width: 100%;
  .flex-start {
  	justify-content: flex-start;
  }
  .flex-end {
  	justify-content: flex-end;
  }
  .flex-center {
  	justify-content: center;
  }
  > * {
  	display:flex;
  	flex: 1;
  }
}