// Variables

@import "variables"; //Materialize custom variable file
@import "../../components/variables"; //Materialize variable file

@import "../../theme-components/theme-variables"; //Theme variable file
@import "theme-variables"; //Theme custom variable file

// Typography
// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,500,600);
// Font Variables
$title: 'Muli', sans-serif;
$font-stack: 'Muli', sans-serif;
$content: 'Muli', sans-serif;


// Theme custom scss

input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid $secondary-color;
  -webkit-box-shadow: 0 1px 0 0 $secondary-color;
  box-shadow: 0 1px 0 0 $secondary-color;
}

input:not([type]):focus:not([readonly])+label, input[type=text]:not(.browser-default):focus:not([readonly])+label, input[type=password]:not(.browser-default):focus:not([readonly])+label, input[type=email]:not(.browser-default):focus:not([readonly])+label, input[type=url]:not(.browser-default):focus:not([readonly])+label, input[type=time]:not(.browser-default):focus:not([readonly])+label, input[type=date]:not(.browser-default):focus:not([readonly])+label, input[type=datetime]:not(.browser-default):focus:not([readonly])+label, input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, input[type=tel]:not(.browser-default):focus:not([readonly])+label, input[type=number]:not(.browser-default):focus:not([readonly])+label, input[type=search]:not(.browser-default):focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label {
  color: $secondary-color;
}

.input-field .prefix.active {
  color: $secondary-color;
}

[type="checkbox"]:checked+span:not(.lever):before {
  border-right: $radio-border;
  border-bottom: $radio-border;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.input-field.col label {
	left: .975rem;
}

[type="checkbox"]+span:not(.lever):before,
[type="checkbox"]:not(.filled-in)+span:not(.lever):after {
	left: 0.375rem;
}
[type="checkbox"]:checked+span:not(.lever):before {
	left: 0px;

}
span:not(.lever):before {
	border: 2px solid #6B6F82;
}
[type="checkbox"]:checked {
  border-right: 2px solid #ff4081 !important;
  border-bottom: 2px solid #ff4081 !important;

}

.card {
	.card-content {
		padding: 1.5rem;
		.card-title {
			font-size: 1.25rem;
			line-height: 110%;
			color: #000;
			margin-bottom: 1rem;
		}
		.card-subtitle {
	    margin-top: -1rem;
	    margin-bottom: 1rem;
		}
	} 
}
.home .card {
	margin-bottom: 2rem;
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating), .card .card-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating):hover {
  color: #039be5;
  margin:0;
  text-transform: none;
}

.flex-centered-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width:100%;

  .input-field {
    text-align:center;
  }
}

svg {
  vertical-align: bottom;
}

.list {
  padding-left:1rem;
  > li {
    margin-bottom:0.5rem;
  }
}

.dropdown-content li>a, .dropdown-content li>span {
  color: #000;
}

input.errored {
  border-bottom: 1px solid #a50000 !important;
}

i.small {
  font-size: 2rem !important;
}

.sidenav {
  width:280px !important;
}
nav .sidenav-trigger {
	display:none;
	height: auto;
	margin: 0 0.5rem;
	&.nav-trigger {
		display:block;
	  position: relative;
	  top: 10px;
	  left: 10px;
	}
}

.sidenav-trigger, .brand-logo {
	padding:0;
	background-color:transparent !important;
	height: auto;
	width: auto;
	box-shadow: none !important;
	font-size:4rem;
}
.sidenav .user-view {
	padding: 1rem;
	margin:0;

	> a.brand-logo:hover {
		background-color:transparent !important;
	}
}
img.brand {
	height:3rem;
	background-color:transparent !important;
}
.sidenav li {
	line-height: 1.5rem !important;
}
.sidenav li>a>i, .sidenav li>a>[class^="mdi-"], .sidenav li>a li>a>[class*="mdi-"], .sidenav li>a>i.material-icons {
	color: inherit;
}

nav i, nav [class^="mdi-"], nav [class*="mdi-"], nav i.material-icons {
	line-height: 40px;
	height: auto;
}


nav {
  height: 64px;
  line-height: 64px;
}

nav.main-nav {
	display:none;
}

.home {
	margin:0 1rem;
}
@media only screen and (min-width: 993px) {
  .home {
  	padding-left: 280px !important;
  }
}

@media only screen and (max-width: 992px) {
	nav.main-nav.authenticated {
  	display:block;
  }
}

.sidenav {
	box-shadow: 0px 4px 8px rgba(68, 76, 116, 0.08) !important;
	li.active > a, li > a:hover {
	  box-shadow: 0px 0px 6px -11px #000 !important;
	  background: #F6F7FB !important;
	  background: #F6F7FB !important;
	  color: $secondary-color !important;
	  > i {
	  	color: $secondary-color !important;
	  }
	}
}
.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav {
	box-shadow: 4px 0px 8px rgba(68, 76, 116, 0.08) !important;
}


nav.breadcrumbs {
	box-shadow:none !important;
	height:auto;
	> .nav-wrapper > .col {
    line-height: 2.25rem;
    padding: 0.75rem !important;
	}
	&.transparent {
		a.breadcrumb, a.breadcrumb:before {
			color: rgba(107, 111, 130, 0.5);
			// line-height:2rem;
		}
	}
}

.ct-chart {
	margin: 0;

	&.ct-production {
	 	height: 240px;
		.ct-series-a .ct-line {
			stroke: #ED1C24;
		}
	}

	&.ct-account {
		height: 200px;
		.ct-series-a .ct-slice-donut {
			stroke: #00BCFF;
		}
		.ct-series-b .ct-slice-donut {
			stroke: rgba(0, 188, 255, 0.2);
		}
	}

	&.ct-orders {
		height: 150px;
		.ct-series-a .ct-bar {
			stroke: #ED1C24;
		}
		.ct-series-b .ct-bar {
			stroke: #00BCFF;
		}
	}

	&.ct-convertion-rate {
		height: 150px;
		.ct-bar {
			stroke-width: 3.5rem;
		}
		.ct-series-a .ct-bar {
			stroke: #ED1C24;
		}
		.ct-series-b .ct-bar {
			stroke: #00BCFF;
		}
	}

	&.ct-today-revenue {
		height: 200px;
		margin-bottom:-1.5rem;
		.ct-series .ct-point {
			stroke: #00BCFF;
			stroke-width: 1rem;
		}
		.ct-series .ct-line {
			stroke: #00BCFF;
		}
		.ct-series-a .ct-area {
			fill: rgba(0, 188, 255, 0.8);
		}
	}
}


.orders-legend {
	position:relative;
	font-size:0.875rem;
	h6 {
		font-size:1.125rem;
		margin-top:0;
		margin-bottom:0;
	}
	p {
		line-height:1rem;
	}
	> .col {
		margin-bottom: 1rem;
		> .row {
			margin-bottom:1rem;
		}
	}
	&:last-child {
		margin-bottom:0;
		> .col {
			margin-bottom:0;
		}
	}
	&.icons {
		margin:2rem 0 !important;
		> .col {
			padding-left: 2rem !important;
			position:relative;
			&:before {
				content: '';
				height:0.75rem;
				width:0.75rem;
				position:absolute;
				left:0.5rem;
				top:0.25rem;
				// border-radius:7px;
			}
			&.series-a:before {
				background-color: #00BCFF;
			}
			&.series-b:before {
				background-color: #ED1C24;
			}
		}

	}
}

.affil-link {
  display: flex;
  flex-direction: row;
  margin-bottom:1rem;
  > span {
  	font-size: 0.8rem;
    line-height: 1rem;
    display: inline-block;
    align-self: center;
    margin-right:0.5rem;
  }
  > button.btn {
  	padding:0 0.25rem;
  	display:flex;
  }
}

.more-link {
	display:inline-block;
	padding: 1rem 0;
}