.app-wrap.flex {
	@media(min-width:767px) {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 1.5rem 0;
	}
}

.auth-wrap {
	width: 100%;
	@media(min-width: 767px) {
		max-width: 480px;
		&.wide {
			max-width: 767px;
		}
	}

}

.abs {
	position: fixed;
	z-index: 10000;
	&.bottom-left {
		bottom: 1rem;
		left: 1rem;
	}
	&.bottom-right {
		bottom: 1rem;
		right: 1rem;
	}
}