// ==========================================================================
// Materialize theme variables
// Extended from : theme-components/variables.scss
// ==========================================================================
//
// Table of Contents:
//
//  1. Colors
//  2. Sidenav

//colors
$breadcrumbs-bg: transparent;

//Sidenav
$sidenav-active-text-color: $white;
$sidenav-active-box-shadow: 3px 3px 20px 0 #5f1816;
$sidenav-active-bg: linear-gradient(45deg, #c1302d, #E53935);