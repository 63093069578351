.program-task-list {
	padding-bottom: 2rem;
}
.program-task-row {
	width:100%;
	border-top:1px solid #f3f3f3;
}
.program-task-item {
	position:relative;
	min-height:48px;
	min-height:3rem;
	> textarea {
		width: calc(100% - 96px);
		width: calc(100% - 6rem);
		resize: none;
		padding:12px 0;
		padding:0.75rem 0;
		&, &:focus, &:active {
			border:none;
			box-shadow:none;
		}
		&::placeholder {
			color:#ccc !important;
	  }
	}
}
.program-task-toggle {
	width:48px;
	width:3rem;
	height:48px;
	height:3rem;
	float:left;
	padding:0;
	cursor:pointer;
	&, &:active, &:focus {
		border:none;
		box-shadow:none;
		background:none;
	}
	&.half {
		height:2rem;
	}
}
.program-task-text {
	position:relative;
	min-height: 3rem;
	padding: 15px 48px 15px 0;
	padding: 0.9rem 3rem 0.9rem 0;
	float:left;
  text-align: justify;
  line-height: 1.25rem;
  width: calc(100% - 48px);
  width: calc(100% - 3rem);

  > a {
  	&, &:hover, &:active, &:focus {
	  	color:#000;
  	}
  }
  &.completed > a {
  	&, &:hover, &:active, &:focus {
  		text-decoration: line-through !important;
	    color: #ccc;
  	}
  }
}
.program-task-video-play {
	position:relative;
	float:left;
  width: calc(100% - 48px);
  width: calc(100% - 3rem);
}
.program-task-video-play-button {
	position: relative;
  padding: 0;
  border: none;
  width: calc(100% - 48px);
  width: calc(100% - 3rem);
  cursor:pointer;
  &, &:focus, &:active {
	  box-shadow:none;
	}
  > svg {
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    position: absolute;
	}
	.iframe-container {
		width:100%;
		position:relative;
  	overflow: hidden;
    padding-top: 56.25%;
	  > iframe {
	  	position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	  	width:100%;
	  	border:none;
	  }
		
	}
}
.program-task-video-thumb {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.program-task-video-thumb-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.3);
}

.program-task-input-completed {
	float:left;
	color:#ccc;
  padding: 8px 0;
  padding: 0.5rem 0;
	white-space: pre-wrap;
	overflow-wrap: break-word;
  max-width: calc(100% - 6rem);
}

.program-task-video-overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.7);
  cursor:default;
}