.challenge-activator {
	margin: -16px -16px 0 -16px;
	margin: -1rem -1rem 0 -1rem;
	padding: 16px;
	padding: 1rem;
	> a {
    font-size: 1.115rem;
		&, &:hover {
			color:#0195ff;
		}
		> svg {
			vertical-align: text-top;
		}
	}
	&.active {
		background: #0195ff;
		> a {
			&, &:hover {
				color:#fff;
			}
		}
	}
	
}

.app-challenge-tasks-form {
	input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: transparent !important;
  }

  input, input:focus, input:active, select, select:focus, select:active {
  	outline:none !important;
    box-shadow: none !important;
    border-radius:0;
    border:none;
    border-bottom: 1px solid #ccc;
    padding-left:0;
    padding-right:0;
    padding-top: 12px !important;
    padding-top: 0.75rem !important;
    padding-bottom: 12px !important;
    padding-bottom: 0.75rem !important;
    padding-left: 0 !important;
    height:3rem;
    background:transparent;
  }

  .select-block > svg {
  	color: inherit;
    position: absolute;
    pointer-events: none;
    top: 10px;
    line-height: 1;
    right: 0;
    width: 2em;
    text-align: center;
    top:16px;
  	top:1rem;
  	width:16px;
  	width:1rem;
  }
}

.challenges_form-label {
	text-transform:uppercase;
	font-weight:300;
	font-size:1.25rem;
	margin-bottom:0;
	color: #222;
}

.challenges_form-days {
  margin-left: -1rem;
	margin-right: -1rem;
	margin-bottom: -1rem;
}

.challenges_form-days, .challenges_form-days-task_list {
	padding:0;
	list-style:none;
}

.challenges_form-days-item {
	border-bottom: 1px solid #f1f1f1;
  padding: 0 1rem;
}

.challenges_form-days-task_create {
	margin-bottom: 16px;
	margin-bottom: 1rem;
	input, select, label {
		font-weight:300;
	}
	label {
		line-height:48px;
		line-height:3rem;
	}
	.task_input_col {
		margin-bottom:2rem;
		> label {
			width:100%;
		}
	}
	> div {
		@media(max-width:767px) {
			margin-bottom:16px;
			margin-bottom:1rem;
			padding:0 !important;
		}
	}
	.task-type-options {
		display: inline-block;
    margin-left: 1rem;
		> li {
			padding:0;
			display:inline;
			> button {
				background:none;
				box-shadow:none;
				border:none;
				display:inline-block;
				padding:0 4px;
				padding:0 0.25rem;
			}
			> button, > button:focus {
				color:#ccc;
				cursor:pointer;

			}
			> button:hover, &.active > button {
				svg {
					fill:#0195ff;
				}
			}
			
		}
	}

}

.task-type_special {
	border-radius: 2px;
	background: #fff;
	display: inline-block;
	line-height: 2rem;
	box-shadow: 0 0 13px -9px #000;
	position:relative;
	width:200px;
	max-width:100%;
	button.remove-type_special {
		&, &:hover {
			border:none;
			background:none;
			box-shadow:none;
	    position: absolute;
	    color: #000;
	    background: #fff;
	    top: 0;
	    right: 0;
	    height: 2rem;
	    width: 2rem;
	    line-height: 1.6rem;
	    display: flex;
	    justify-content: center;
	    text-align: center;
	    cursor:pointer;
	    > .icon {
	    	height:auto;
	    }
			
		}
	}
	.type_special_wrap {
		padding: 8px 32px 8px 8px;
		padding: 0.5rem 2rem 0.5rem 0.5rem;
		word-break: break-all;
		font-size:12px;
		font-size:0.75rem;
		line-height:16px;
		line-height:1rem;
		> a {
	    text-decoration: underline !important;
	    color: #3097D1;
	    > svg {
	    	height:32px;
	    	height:2rem;
	    }
		}
	}
	img {
		width:100%;
		object-fit:cover;
		margin-bottom:-1rem;
	}
}

.challenges_form-days-task_list {
	font-weight:300;
	margin-top:0;
	> li {
		margin-left: -16px;
		margin-left: -1rem;
    margin-right: -16px;
    margin-right: -1rem;
    padding: 8px 24px;
    padding: 1rem;
    line-height: 32px;
    line-height: 2rem;
    transition: margin 0.1s ease-in-out;
  }
	> li:nth-child(odd) {
		background-color:#f8fcff;
	}
	> li:nth-child(even) {
		background-color:#edf6fd;
	}

	> li:last-child {
		// -webkit-box-shadow: inset 0 -2px 8px -7px #000;
  //   box-shadow: inset 0 -2px 8px -7px #000;
	}
	> li.task-edited {
    margin: 0 -20px;
    padding-left: 22px;
    padding-right: 22px;
    box-shadow: 0 0 8px -5px #000;
    background-color: #eaf7f6;
    outline: 1px solid #28a745;
	}

}

.task-actions {
	text-align:right;
	@media(max-width:576px) {
		& {
			margin-top:1rem;
			// text-align:left;
		}
	}
	
	> button {
		display:inline-block;
		// height:1.75rem;
		font-size:0.75rem;
		font-size:12px;
		vertical-align:top;
		> svg {
			vertical-align: text-top;
		}
	}
}

.challenges_form-days-task_list_item.toggle {
	display:block;
	width:100%;
	position:relative;
  padding: 0.75rem 0;
  background:none;
  border:none;
  cursor:pointer;
  text-align:left;
  &, &:hover, &:active {
  	box-shadow:none;
 	}
	> svg {
		position:absolute;
		right:0;
		top:16px;
		top:1rem;
	}
}