.flex-spread {
	display:flex;
	justify-content: space-between;
}

.align-top-right {
	position:absolute;
	top:1rem;
	right:1rem;
}

.align-padded {
	padding:0.5rem;
}

.app-cookie-disclaimer {
	> .modal-content {
		padding:0.25rem 1rem;
	}
}